import React from "react"
import classes from "./CommonText.module.scss"
import PropTypes from "prop-types"

const CommonText = ({ title, descriptions }) => {
  return (
    <div className={classes.wrapper}>
      <h3 className={classes.title}>{title}</h3>
      <ul className={classes.requirements}>
        {Array.isArray(descriptions) ? (
          descriptions.map((description, item) => {
            return <li key={description.id}>- {description.description}</li>
          })
        ) : (
          <li key={555}> {descriptions}</li>
        )}
      </ul>
    </div>
  )
}

CommonText.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
}

export default CommonText
