import React from "react"
import PropTypes from "prop-types"
import classes from "./TechnologyWrapper.module.scss"

const TechnologyWrapper = ({ title }) => {
  return <span className={classes.technologyWrapper}> {title} </span>
}

TechnologyWrapper.propTypes = {
  title: PropTypes.string.isRequired,
}

export default TechnologyWrapper
