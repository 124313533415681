import React from "react"
import classes from "./VacancyPage.module.scss"
import vector from "src/assets/icons/vector.svg"
import SvgIcon from "../SvgIcon"
import CommonText from "../layout/CommonText/CommonText"
import TickerRun from "../layout/TickerRun/TickerRun"
import SEO from "../seo"
import BasicLayout from "../layout/BasicLayout/BasicLayout"
import { graphql } from "gatsby"
import TechnologyWrapper from "../layout/TechnologyWrapper/TechnologyWrapper"

const VacancyPage = ({ data }) => {
  const vacancy = data.vacanciesJson
  return (
    <BasicLayout stickyBtn={true} openCV={true} titleStickyBtn="Send your CV">
      <SEO title={vacancy.name} />
      <div className={classes.container}>
        <h1 className={classes.header}> {vacancy.name} </h1>
        <div className={classes.wrapper}>
          <h3 className={classes.title}>Locations and Tech</h3>
          <div className={classes.vacancyBlock}>
            <div className={classes.requirements}>
              {vacancy.workPlaces.map(workPlace => {
                return (
                  <div key={workPlace.id} className={classes.blockWorkPlace}>
                    <SvgIcon icon={vector} className={classes.icon} />
                    <h5 key={workPlace.id} className={classes.location}>
                      {workPlace.place}
                    </h5>
                  </div>
                )
              })}
            </div>
            <div className={classes.technologyWrapper}>
              {vacancy.tags.map(tag => {
                return <TechnologyWrapper title={tag.name} key={tag.id} />
              })}
            </div>
            <hr className={classes.hr} />
          </div>
        </div>
        <CommonText
          title="Required Skills"
          descriptions={vacancy.requiredSkills}
        />
        <CommonText title="Will Be a Plus" descriptions={vacancy.willBePlus} />
        <CommonText title="We Offer" descriptions={vacancy.weOffer} />
      </div>
      <TickerRun text="Send CV" stringTicker yellow={false} none />
    </BasicLayout>
  )
}
export default VacancyPage

export const query1 = graphql`
  query($name: String!) {
    vacanciesJson(name: { eq: $name }) {
      workPlaces {
        id
        place
      }
      id
      name
      tags {
        id
        name
      }
      weOffer {
        description
        id
      }
      willBePlus {
        description
        id
      }
      requiredSkills {
        description
        id
      }
    }
  }
`
